import React, { useEffect, useState } from "react";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import PaginatedTable from "../../components/table/PaginatedTable";
import { Link } from "react-router-dom";
import Avatar from '../../asset/img/Avatar.png';
import ShowMediaModal from "./ShowMediaModal";
import { ReactComponent as Ban } from '../../asset/icons/Ban.svg'
import { ReactComponent as Play } from '../../asset/icons/PlayButton.svg'
import { ReactComponent as ImageIcon } from "../../asset/icons/ImageIcon.svg";


import ReactPlayer from "react-player";
import BlockWithWarning from "./BlockWithWarning";

function BlockedPosts({ reFetchTrigger }) {
    const [page, setPage] = useState(1);

    let url = `${endpoints.postReports.blockedPosts}?page=${page}&limit=10`;

    const fetchUtils = useAxiosGet(url);
    useEffect(() => {
        if (reFetchTrigger) {
            fetchUtils.reFetch(); // Re-fetch data when the tab is active
            setPage(1)
        }
    }, [reFetchTrigger]);

    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };

    const cellModifier = {
        review_report: ({ row }) => {
            return (
                <Link to={'/alumini-blocked-post-report-list'} state={{ ...row.original }}>
                    View Reports
                </Link>
            );

        },
        post_created_user_dp: ({ row }) => {

            return (
                <ShowMediaModal
                    renderModalBody={() => (
                        <div style={{ padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <img
                                src={row?.original?.post_created_user_dp || Avatar}
                                alt='Avatar'
                                width={'100%'}
                                style={{ borderRadius: 5 }}
                            />
                        </div>
                    )}
                >
                    <img
                        src={row?.original?.post_created_user_dp || Avatar}
                        alt='Avatar'
                        className='rounded-circle shadow-sm'
                        style={{
                            border: '3px solid white',
                            width: '50px',
                        }}
                    />
                </ShowMediaModal>

            );

        },
        block_post: ({ row }) => {
            return (
                <BlockWithWarning
                    configBody={{ postId: row.original?.alumni_post_id }}
                    onAfterDelete={() => fetchUtils.reFetch()}
                />
            );
        },
        post_content_url: ({ row }) => {
            const mediaType = row.original?.media_type;
            const renderMedia = (mediaType) => {
                switch (mediaType) {
                    case 'image/jpeg':
                        return (
                            <ShowMediaModal
                                renderModalBody={() => (
                                    <div style={{ padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                        <img
                                            src={row?.original?.post_content_url || Avatar}
                                            alt='Avatar'
                                            width={'100%'}
                                            style={{ borderRadius: 5 }}
                                        />
                                    </div>
                                )}
                            >
                                <ImageIcon style={{ width: '100%', height: '30px', }} />
                            </ShowMediaModal>
                        );
                    case 'video/mp4':
                        return (<ShowMediaModal
                            renderModalBody={() => (
                                <div style={{ padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <ReactPlayer
                                        url={row?.original?.post_content_url || ''}
                                        width={'100%'}
                                        playing={true}
                                        controls={true}
                                    />
                                </div>
                            )}
                        >
                            <Play style={{ width: '100%', height: '30px', background: '#50acf2', borderRadius: 50 }} />
                        </ShowMediaModal>
                        ); // Replace with a video thumbnail if needed
                    default:
                        return 'N/A';
                }
            };

            return <>{renderMedia(mediaType)}</>;
        }
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                {...viewProps.BlockedPosts}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                immutable
                irremovable
                insertable={false}
                showSearch={false}
                downloadable={false}
                cellModifier={cellModifier}
            />
        </>
    );
}

export default BlockedPosts;
