import React, { useState } from "react";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import StudentCouncilForm from "./StudentCouncilForm";
import { useAuthenticationState } from "../../context/Auth.context";
import PaginatedTable from "../../components/table/PaginatedTable";
import GlobalSearch from "../../components/GlobalSearch";
import DownloadDataAsExcel from "../../components/DownloadDataAsExcel";

function StudentCouncil() {
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    // const url = !searchTerm
    //     ? `${endpoints.studentCouncil.get}?page=${page}&limit=10`
    //     : `${endpoints.studentCouncil.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;

    let url = `${endpoints.studentCouncil.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.studentCouncil.downloadExcel;

    if (searchTerm) {
        url = `${endpoints.studentCouncil.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const { user } = useAuthenticationState();

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.studentCouncil}
                {...viewProps.StudentCouncil}
                Form={StudentCouncilForm}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                addBtnLabel="Add Member"
                immutable
                irremovable={
                    user.Role !== "ADMIN" &&
                    user.Role !== "CLASSREP + STUDENT COUNCIL" &&
                    user.Role !== "STUDENT COUNCIL"
                }
                insertable={
                    user.Role === "ADMIN" ||
                    user.Role !== "CLASSREP + STUDENT COUNCIL" ||
                    user.Role !== "STUDENT COUNCIL"
                }
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search student by name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Student Council Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default StudentCouncil;
