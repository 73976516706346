import React, { useEffect, useState } from "react";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import PaginatedTable from "../../components/table/PaginatedTable";
import { Link, useLocation } from "react-router-dom";
import Avatar from "../../asset/img/Avatar.png";
import ShowMediaModal from "./ShowMediaModal";
import { ReactComponent as Play } from "../../asset/icons/PlayButton.svg";
import { ReactComponent as Image } from "../../asset/icons/ImageIcon.svg";


import ReactPlayer from "react-player";
import ProfileCard from "./ProfileCard";

function BlockedReportLists() {
    const [page, setPage] = useState(1);
    const { state } = useLocation();
    const renderMedia = (mediaType, imageUrl, videoUrl, postDescription) => {
        switch (mediaType) {
            case "image/jpeg":
                return (
                    <ShowMediaModal
                        renderModalBody={() => (
                            <div
                                style={{
                                    padding: 3,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={imageUrl || Avatar}
                                    alt="Avatar"
                                    width={"100%"}
                                    style={{ borderRadius: 5 }}
                                />
                            </div>
                        )}
                    >
                        <Image style={{ width: '100%', height: '30px', }} title="View Post" />
                    </ShowMediaModal>
                );
            case "video/mp4":
                return (
                    <ShowMediaModal
                        renderModalBody={() => (
                            <div
                                style={{
                                    padding: 3,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <ReactPlayer
                                    url={videoUrl || ""}
                                    width={"100%"}
                                    playing={true}
                                    controls={true}
                                />
                            </div>
                        )}
                    >
                        <Play style={{ width: '100%', height: '30px', }} title="View Post" />
                    </ShowMediaModal>
                ); // Replace with a video thumbnail if needed
            default:
                return <div style={{ color: "red" }} title="post description">{postDescription}</div>;
        }
    };
    let url = `${endpoints.postReports.listBlockedPostReports}?page=${page}&limit=10&postId=${state.alumni_post_id}`;

    const fetchUtils = useAxiosGet(url);

    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };

    const cellModifier = {
        post_created_user_dp: ({ row }) => {
            return (
                <ShowMediaModal
                    renderModalBody={() => (
                        <div
                            style={{
                                padding: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={
                                    row?.original?.post_created_user_dp ||
                                    Avatar
                                }
                                alt="Avatar"
                                width={"100%"}
                                style={{ borderRadius: 5 }}
                            />
                        </div>
                    )}
                >
                    <img
                        src={row?.original?.post_created_user_dp || Avatar}
                        alt="Avatar"
                        className="rounded-circle shadow-sm"
                        style={{
                            border: "3px solid white",
                            width: "50px",
                        }}
                    />
                </ShowMediaModal>
            );
        },
        reported_user_dp: ({ row }) => {
            return (
                <ShowMediaModal
                    renderModalBody={() => (
                        <div
                            style={{
                                padding: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={row?.original?.reported_user_dp || Avatar}
                                alt="Avatar"
                                width={"100%"}
                                style={{ borderRadius: 5 }}
                            />
                        </div>
                    )}
                >
                    <img
                        src={row?.original?.reported_user_dp || Avatar}
                        alt="Avatar"
                        className="rounded-circle shadow-sm"
                        style={{
                            border: "3px solid white",
                            width: "50px",
                        }}
                    />
                </ShowMediaModal>
            );
        },
        report_reason: ({ row }) => {
            return (
                <div style={{ color: "red" }}>{row.original.report_reason}</div>
            );
        },
    };

    return (
        <>


            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                {...viewProps.ReportList}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                name={<ProfileCard
                    userName={state.post_created_user_name}
                    dpUrl={state.post_created_user_dp}
                    email={state.post_created_user_email}
                    renderPost={renderMedia(
                        state.media_type,
                        state.post_content_url,
                        state.post_content_url,
                        state.post_description
                    )}
                />}
                immutable
                irremovable
                insertable={false}
                showSearch={false}
                downloadable={false}
                cellModifier={cellModifier}
            />
        </>
    );
}

export default BlockedReportLists;
