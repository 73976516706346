import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function ShowMediaModal({
    renderModalBody = () => { },
    onHiding = () => { },
    modalAttrs,
    children,
    disabled,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div
                type={`${disabled ? '' : 'button'}`}
                className="d-inline-block"
                style={{ opacity: disabled ? '.5' : '1' }}
                onClick={() => {
                    if (disabled) return;
                    setIsModalOpen(true);
                }}
            >
                {children}
            </div>

            <Modal
                centered
                show={isModalOpen}
                onHide={() => {
                    onHiding();
                    closeModal();
                }}
                size='sm'
                {...modalAttrs}
            >
                <Modal.Body style={{ padding: 0 }}>
                    {renderModalBody(closeModal)}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShowMediaModal;
