import React, { useState } from 'react';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CityChapterForm from './CityChapterForm';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import BulkUpload from '../../components/BulkUpload';
import errorObjects from './CityChapterErrorObjects';
import MappingBulkUploadUserGuide from './MappingBulkUploadUserGuide';
import CustomToolTip from './CustomToolTip';
import DownloadExcelWithWantedHeads from '../../components/DownloadExcelWithWantedColumns';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
// import ReactExport from 'react-export-excel-xlsx-fix';
// import { ReactComponent as Download } from '../../asset/icons/Download.svg';
// import { Button } from 'react-bootstrap';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CityChapters() {
    // const fetchUtils = useAxiosGet(endpoints.cityChapters.get);
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    let url = `${endpoints.cityChapters.get}/?page=${page}&limit=10`
    let downloadUrl = endpoints.cityChapters.downloadExcel;

    if (searchTerm) {
        url = `${endpoints.cityChapters.get}/?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }


    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall || searchTerm });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    let cityChapters = [];

    // if (user?.CCH?.CityChapter_ID) {
    //     cityChapters = fetchUtils.response.result?.filter(
    //         (city) => city.CityChapter_ID === user?.CCH?.CityChapter_ID
    //     );
    // }

    const cellModifier = {
        AlumniCityChapterMap: ({ row }) => {
            const cityChapterIds = row.original.CityChapter_ID;
            if (
                user?.Role === 'ADMIN' ||
                user?.CCH?.CityChapter_ID === cityChapterIds
            ) {
                return (
                    <Link to={'/city-chapter-map'} state={row.original}>
                        City chapter Alumni list
                    </Link>
                );
            } else {
                return <span>NA</span>;
            }
        },
    };

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    return (
        <>
            <PaginatedTable
                isCch={user?.CCH?.CityChapter_ID}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                setTriggerApiCall={setTriggerApiCall}
                endpoints={endpoints.cityChapters}
                Form={CityChapterForm}
                addBtnLabel={'Add City Chapters'}
                downloadExcelName='City Chapters'
                name={
                    user.Role === 'ADMIN' || user.Role === 'GCM' ? (
                        <div style={{ marginTop: '-30px' }}>
                            <div>{'City Chapters'}</div>
                            <MappingBulkUploadUserGuide />
                        </div>
                    ) : (
                        'City Chapters'
                    )
                }
                {...viewProps.CityChapters}
                cellModifier={cellModifier}
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                {...fetchUtils}
                response={
                    cityChapters?.length
                        ? cityChapters
                        : fetchUtils.response.result
                }
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search ..."
                    />
                }
                downloadable={false}
                headerExtras={
                    <>
                        {(user.Role === 'ADMIN' || user.Role === 'GCM') && (
                            <>
                                <BulkUpload
                                    isSingleApiForUpload={true}
                                    urlObjects={{
                                        templateUrl:
                                            endpoints.cityChapterAlumniMap
                                                .bulkUploadTemplate,
                                        fileUpload:
                                            endpoints.cityChapterAlumniMap
                                                .bulkUpload,
                                        validateFile: '',
                                        initialBulkInsert: '',
                                        bulkUploadConfirm: '',
                                    }}
                                    filename='City Chapter Alumni Details'
                                    afterUpload={fetchUtils.reFetch}
                                    templateExtension='.xlsx'
                                    errorObjects={errorObjects}
                                />
                                <CustomToolTip />
                            </>
                        )}
                        <DownloadDataAsExcel
                            filename={"City Chapter Alumni Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
            />
        </>
    );
}

export default CityChapters;
