import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import ReportReasonForm from './ReportReasonForm';
import DeleteWithWarningTags from '../../components/DeleteWithWarningTags';
import withAlert from '../../components/withAlert';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import toast from 'react-hot-toast';
// import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function ReportReason({ showAlert }) {
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    let url = `${endpoints.reportReasons.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.reportReasons.downloadExcel;

    if (searchTerm) {
        url = `${endpoints.reportReasons.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const cellModifier = {
        Delete: ({ row }) => (
            <div className='td text-center'>
                <DeleteWithWarningTags
                    title={'Post Report Reason'}
                    configBody={{
                        ...row.original,
                    }}
                    className='mx-auto'
                    onAfterDelete={() => {
                        fetchUtils.reFetch();
                        toast.success(
                            'The reason has been removed successfully'
                        );
                    }}
                    endpoint={endpoints.reportReasons.delete}
                />
            </div>
        ),
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.reportReasons}
                {...viewProps.ReportReasons}
                relevants={[
                    ...(viewProps.ReportReasons.relevants || []),
                    ...['Delete'],
                ]}
                columnHeads={[
                    ...(viewProps.ReportReasons.columnHeads || []),
                    ...['Delete'],
                ]}
                Form={ReportReasonForm}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                irremovable
                cellModifier={cellModifier}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search Reason"
                    />
                }
                downloadable={false}

            />
        </>
    );
}

export default withAlert(ReportReason);
