import { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Dashboard } from '../../asset/icons/Dashboard.svg';
import { ReactComponent as Alumni } from '../../asset/icons/Alumni.svg';
import { ReactComponent as Mapping } from '../../asset/icons/CityChapterAlumni.svg';
import { ReactComponent as CityChapter } from '../../asset/icons/CityChapter.svg';
import { ReactComponent as Event } from '../../asset/icons/Event.svg';
import { ReactComponent as Announcement } from '../../asset/icons/Announcement.svg';
import { ReactComponent as LookingforJob } from '../../asset/icons/LookingforJob.svg';
import { ReactComponent as PostedJob } from '../../asset/icons/PostedJob.svg';
import { ReactComponent as MarkAsSafe } from '../../asset/icons/MarkAsSafe.svg';
import { ReactComponent as SafetyResponse } from '../../asset/icons/SafetyResponse.svg';
import { ReactComponent as HelpRequest } from '../../asset/icons/HelpRequest.svg';
import { ReactComponent as BenefitDetail } from '../../asset/icons/BenefitDetail.svg';
import { ReactComponent as Vaccine } from '../../asset/icons/Vaccine.svg';
import { ReactComponent as Enum } from '../../asset/icons/Enum.svg';
import { ReactComponent as DashboardActive } from '../../asset/icons/DashboardActive.svg';
import { ReactComponent as AlumniActive } from '../../asset/icons/AlumniActive.svg';
import { ReactComponent as MappingActive } from '../../asset/icons/CityChapterAlumniActive.svg';
import { ReactComponent as CityChapterActive } from '../../asset/icons/CityChapterActive.svg';
import { ReactComponent as EventActive } from '../../asset/icons/EventActive.svg';
import { ReactComponent as AnnouncementActive } from '../../asset/icons/AnnouncementActive.svg';
import { ReactComponent as LookingforJobActive } from '../../asset/icons/LookingforJobActive.svg';
import { ReactComponent as PostedJobActive } from '../../asset/icons/PostedJobActive.svg';
import { ReactComponent as MarkAsSafeActive } from '../../asset/icons/MarkAsSafeActive.svg';
import { ReactComponent as SafetyResponseActive } from '../../asset/icons/SafetyResponseActive.svg';
import { ReactComponent as HelpRequestActive } from '../../asset/icons/HelpRequestActive.svg';
import { ReactComponent as BenefitDetailActive } from '../../asset/icons/BenefitDetailActive.svg';
import { ReactComponent as VaccineActive } from '../../asset/icons/VaccineActive.svg';
import { ReactComponent as EnumActive } from '../../asset/icons/EnumActive.svg';
import { ReactComponent as Student } from '../../asset/icons/Student.svg';
import { ReactComponent as Faculty } from '../../asset/icons/Faculty.svg';
import { ReactComponent as InstituteMember } from '../../asset/icons/InstituteMember.svg';
import { ReactComponent as StudentActive } from '../../asset/icons/StudentActive.svg';
import { ReactComponent as FacultyActive } from '../../asset/icons/FacultyActive.svg';
import { ReactComponent as InstituteMemberActive } from '../../asset/icons/InstituteMemberActive.svg';
import { ReactComponent as ReportIcon } from '../../asset/icons/ReportIcon.svg';

function Menu({ routes }) {
    const [activeModule, setActiveModule] = useState('');

    const { pathname } = useLocation();

    useEffect(() => {
        const routesInsideModule = routes.filter((route) => !!route.module);

        const currentRouteInsideModule = routesInsideModule.find(
            (route) => pathname === '/' + route.path
        );
        if (currentRouteInsideModule) {
            setActiveModule(currentRouteInsideModule.module);
        } else {
            setActiveModule(pathname);
        }

        return () => {
            setActiveModule('');
        };
    }, [pathname]);

    const menuIconMap = {
        '': { inactive: <Dashboard />, active: <DashboardActive /> },
        'institute-member-dashboard': {
            inactive: <Dashboard />,
            active: <DashboardActive />,
        },
        'student-council-dashboard': {
            inactive: <Dashboard />,
            active: <DashboardActive />,
        },
        'batchRep-dashboard': {
            inactive: <Dashboard />,
            active: <DashboardActive />,
        },
        cityChapterdashboard: {
            inactive: <Dashboard />,
            active: <DashboardActive />,
        },
        'alumni-details': { inactive: <Alumni />, active: <AlumniActive /> },
        'student-details': { inactive: <Student />, active: <StudentActive /> },
        'faculty-details': { inactive: <Faculty />, active: <FacultyActive /> },
        'institute-member-details': {
            inactive: <InstituteMember />,
            active: <InstituteMemberActive />,
        },
        'alumni-personaltag': {
            inactive: <Mapping />,
            active: <MappingActive />,
        },
        'faculty-personaltag': {
            inactive: <Mapping />,
            active: <MappingActive />,
        },
        'student-personaltag': {
            inactive: <Mapping />,
            active: <MappingActive />,
        },
        'city-chapters': {
            inactive: <CityChapter />,
            active: <CityChapterActive />,
        },
        events: { inactive: <Event />, active: <EventActive /> },
        announcements: {
            inactive: <Announcement />,
            active: <AnnouncementActive />,
        },
        'job-seekers': {
            inactive: <LookingforJob />,
            active: <LookingforJobActive />,
        },
        'posted-jobs': { inactive: <PostedJob />, active: <PostedJobActive /> },
        'mark-as-safe': {
            inactive: <MarkAsSafe />,
            active: <MarkAsSafeActive />,
        },
        'sc-mark-as-safe': {
            inactive: <MarkAsSafe />,
            active: <MarkAsSafeActive />,
        },
        'safety-response': {
            inactive: <SafetyResponse />,
            active: <SafetyResponseActive />,
        },
        'help-requests': {
            inactive: <HelpRequest />,
            active: <HelpRequestActive />,
        },
        'benefit-details': {
            inactive: <BenefitDetail />,
            active: <BenefitDetailActive />,
        },
        'vaccine-details': { inactive: <Vaccine />, active: <VaccineActive /> },
        enum: { inactive: <Enum />, active: <EnumActive /> },
        classrepdashboard: {
            inactive: <Dashboard />,
            active: <DashboardActive />,
        },
        'student-job-seekers': {
            inactive: <LookingforJob />,
            active: <LookingforJobActive />,
        },
        'post-reports': { inactive: <ReportIcon />, active: <ReportIcon /> },
    };

    const links = {};

    const createInnerLinks = (linkProps) => {
        const { module, path, name, text } = linkProps;

        links[module] = links[module] ?? [];

        links[module].push(
            <NavLink
                key={path}
                to={'/' + path}
                className={({ isActive }) => {
                    return `${isActive ? 'text-primary' : 'text-dark'
                        } d-block my-2 rounded-smooth`;
                }}
            >
                {text ? text : name}
            </NavLink>
        );
    };

    const createOuterLinks = (linkProps) => {
        const { path, name, text } = linkProps;

        links[name] = (
            <NavLink
                key={path}
                to={'/' + path}
                className={({ isActive }) => {
                    return `${isActive && 'bg-primary text-white'
                        } d-block my-1 p-2 rounded-smooth text-dark`;
                }}
            >
                <Row>
                    <Col sm={1}>
                        {pathname === '/' + path
                            ? menuIconMap[path]?.active
                            : menuIconMap[path]?.inactive}
                    </Col>
                    <Col sm={10}>
                        <div className='ps-2'>{text ? text : name}</div>
                    </Col>
                </Row>
            </NavLink>
        );
    };

    routes
        .filter((route) => !route.hiddenFromMenu || !route.notOnMenu)
        .forEach((route) => {
            if (!!route.module) {
                createInnerLinks(route);
            } else {
                createOuterLinks(route);
            }
        });

    return (
        <Accordion flush>
            {Object.keys(links).map((module, i) =>
                !Array.isArray(links[module]) ? (
                    links[module]
                ) : (
                    <Accordion.Item
                        eventKey={i}
                        key={i}
                        className='bg-transparent border-0'
                    >
                        <Accordion.Button
                            className={`
                                ${activeModule === module
                                    ? 'bg-primary text-white'
                                    : 'bg-transparent text-dark'
                                } my-1 p-2 rounded-smooth 
                                clearFocusDecorate shadow-none`}
                            style={{ fontSize: '12px' }}
                        >
                            {activeModule === module
                                ? menuIconMap[module].active
                                : menuIconMap[module].inactive}

                            <span className='ps-3 text-capitalize'>
                                {module}
                            </span>
                        </Accordion.Button>

                        <Accordion.Body className='py-0 ps-4 ms-3 pe-0'>
                            {links[module]}
                        </Accordion.Body>
                    </Accordion.Item>
                )
            )}
        </Accordion>
    );
}

export default Menu;
