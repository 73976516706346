import React, { useState } from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import TopicOfInterestForm from './TopicOfInterestForm';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import withAlert from '../../components/withAlert';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import StatusIndicator from '../../components/StatusIndicator';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function TopicOfInterest({ showAlert }) {
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");



    let url = `${endpoints.topicOfInterest.get}?page=${page}&limit=10`
    let downloadUrl = endpoints.topicOfInterest.downloadExcel;

    if (searchTerm) {
        url = `${endpoints.topicOfInterest.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }


    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const cellModifier = {
        Approve_Status: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant}
                    status={status[value]?.tag}
                />
            );
        },
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Topic ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Approve_Status !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert('success', 'The topic has been approved');
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.topicOfInterest.approve}
                        configBody={{
                            TopicOfInterest_ID: row.original.TopicOfInterest_ID,
                            TopicofInterest: row.original.TopicofInterest,
                            CREATEDBY: String(row.original.CREATEDBY), //type conversion for case of 0
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Reject Topic?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Approve_Status !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert('success', 'The topic has been rejected');
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.topicOfInterest.reject}
                        configBody={{
                            TopicOfInterest_ID: row.original.TopicOfInterest_ID,
                            TopicofInterest: row.original.TopicofInterest,
                            CREATEDBY: String(row.original.CREATEDBY), //type conversion for case of 0
                        }}
                    // successVarient='success'
                    // dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Reject />
                </span>
            </ModalWrapper>
        ),
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.topicOfInterest}
                {...viewProps.TopicOfInterest}
                Form={TopicOfInterestForm}
                cellModifier={cellModifier}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                addBtnLabel='Add Topic'
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search Tags by name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Topic of interest Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default withAlert(TopicOfInterest);
