import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function BloodDonor() {

    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    const [searchTerm, setSearchTerm] = useState("");



    let url = `${endpoints.statistics.bloodDonor}?page=${page}&limit=10`
    let downloadUrl = endpoints.bloodDonor.downloadExcel;

    if (searchTerm) {
        url = `${endpoints.statistics.bloodDonor}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                {...viewProps.BloodDonor}
                // cellModifier={cellModifier}
                irremovable
                immutable
                insertable={false}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                endpoints={endpoints.bloodDonor}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search donor by name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Topic of interest Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default BloodDonor;
