import React, { useState } from "react";
import PaginatedTable from "../../components/table/PaginatedTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import ModalWrapper from "../../components/ModalWrapper";
import PostWithWarning from "../../components/PostWithWarning";
import { ReactComponent as Approve } from "../../asset/icons/Approve.svg";
import { ReactComponent as Reject } from "../../asset/icons/Block.svg";
import { useAuthenticationState } from "../../context/Auth.context";
import StatusIndicator from "../../components/StatusIndicator";
import withAlert from "../../components/withAlert";
import GlobalSearch from "../../components/GlobalSearch";
import DownloadDataAsExcel from "../../components/DownloadDataAsExcel";

function PostedJobs({ showAlert }) {
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    let getApi = endpoints.postedJobs.get;

    let downloadUrl = endpoints.postedJobs.downloadExcel;

    if (["DELEGATE", "DELEGATE + CCH"].includes(user.Role)) {
        getApi = endpoints.postedJobs.delegate + user.DELEGATE_BATCH_CODE;
        downloadUrl = endpoints.postedJobs.downloadExcelDelegate+ user.DELEGATE_BATCH_CODE;
    } else if (['STUDENTCOUNCIL', 'CLASSREPRESENTATIVE', 'CLASSREP + STUDENTCOUNCIL'].includes(user.Role)) {

        getApi = endpoints.postedJobs.studentJobList;
        downloadUrl = `${endpoints.postedJobs.downloadExcelStudent}`
    } else {
        getApi = endpoints.postedJobs.get;
        downloadUrl = endpoints.postedJobs.downloadExcel;
    }

    let url = `${getApi}?page=${page}&limit=10`;

    if (searchTerm) {
        url = `${getApi}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }


    const fetchUtils = useAxiosGet(url);

    const cellModifier = {
        Alumni_JobListing_STATUS: ({ value, row }) => {
            const status = {
                1: { tag: 'Active', variant: 'bg-info' },
                0: { tag: `Removed by ${row.original?.ALUMNI_Name}`, variant: 'bg-secondary' },
                2: { tag: 'Inactive', variant: 'bg-danger' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={"Approve Job ?"}
                modalAttrs={{ size: "sm" }}
                disabled={row.original.Alumni_Joblisting_Approve_Status !== 3 || row.original?.Alumni_JobListing_STATUS === 0}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                "success",
                                "The job request has been Approved"
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't approve this job.` || 'The job has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.postedJobs.respond}
                        configBody={{
                            Alumni_Joblisting_Approve_Status: "1",
                            Alumni_JobListing_ID: String(
                                row.original.Alumni_JobListing_ID
                            ),
                            Alumni_JobListing_NAME:
                                row.original.Alumni_JobListing_NAME,
                            CREATEDBY: row.original.CREATEDBY,
                            NOTIFICATION_CONTENT: "Posted Job Approved",
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={"Block Job ?"}
                modalAttrs={{ size: "sm" }}
                disabled={row.original.Alumni_Joblisting_Approve_Status !== 3 || row.original?.Alumni_JobListing_STATUS === 0}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                "success",
                                "The job request has been Rejected"
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't reject this job.` || 'The job has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.postedJobs.respond}
                        configBody={{
                            Alumni_Joblisting_Approve_Status: "2",
                            Alumni_JobListing_ID:
                                row.original.Alumni_JobListing_ID,
                            Alumni_JobListing_NAME:
                                row.original.Alumni_JobListing_NAME,
                            CREATEDBY: row.original.CREATEDBY,
                            NOTIFICATION_CONTENT: "Posted Job Rejected",
                        }}
                    // successVarient='success'
                    // dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        // Alumni_JobListing_STATUS: ({ value }) => {
        //     if (Number(value) === 0) {
        //         return "Inactive";
        //     }
        //     if (Number(value) === 1) {
        //         return "Active";
        //     }
        //     return value;
        // },
        Alumni_JobListing_ExpiryDATE: ({ value }) => {
            return new Date(value).toLocaleDateString();
        },
        Alumni_JobListing_TYPE: ({ value }) => {
            const types = {
                1: "Intern",
                2: "Part-Time",
                3: "Full Time",
            };
            return types[value] ?? value;
        },
        Alumni_Joblisting_Approve_Status: ({ value }) => {
            const status = {
                1: { tag: "Approved", variant: "bg-success" },
                2: { tag: "Rejected", variant: "bg-danger" },
                3: { tag: "Not Responded", variant: "bg-warning" },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || "bg-warning"}
                    status={status[value]?.tag || "Not Responded"}
                />
            );
        },
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                {...viewProps.PostedJobs}
                cellModifier={cellModifier}
                endpoints={endpoints.postedJobs}
                // irremovable
                insertable={false}
                immutable
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search ..."
                    />
                }
                downloadable={false}
                headerExtras={
                    downloadUrl && <DownloadDataAsExcel
                        filename={"Student Posted Job Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default withAlert(PostedJobs);
