import ModalWrapper from "../../components/ModalWrapper";
import PostWithWarning from "../../components/PostWithWarning";
import { ReactComponent as Ban } from "../../asset/icons/Ban.svg";
import extractErrorFromRes from "../../helpers/extractErrorFromRes";
import toast from "react-hot-toast";
import endpoints from "../../endpoints";

function BlockWithWarning({
    title,
    endpoint,
    configBody,
    onAfterDelete,
    disabled,
}) {
    return (
        <ModalWrapper
            modalTitle={"Block This Post"}
            modalAttrs={{ size: "sm" }}
            disabled={disabled}
            renderModalBody={(closeModal) => (
                <PostWithWarning
                    onSuccess={() => {
                        closeModal();
                        onAfterDelete();
                        toast.success("Post Blocked Successfully");
                    }}
                    onError={(err) =>
                        toast.error(extractErrorFromRes(err) || 'something went wrong')
                    }
                    onCancel={closeModal}
                    endpoint={endpoints.postReports.blockPost}
                    configBody={configBody}
                />
            )}
        >
            <div
                className="btnAnime"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 50,
                }}
            >
                <Ban style={{ color: "red", width: "100%", height: "30px" }} />
            </div>
        </ModalWrapper>
    );
}

export default BlockWithWarning;
