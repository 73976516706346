import React from 'react';
import { Col, Row } from 'react-bootstrap';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import AlumniJobDomain from '../dashboard/AlumniJobDomain';
import Chip from '../dashboard/Chip';
import ECMembers from '../dashboard/ECMembers';
import EntreInd from '../dashboard/EntreInd';
import AlumniGraduated from '../dashboard/AlumniGraduated';
import CityChapterInclusion from '../dashboard/CityChapterInclusion';
import Loader from '../../components/Loader';
import Entrepreneurs from './EntrepreneurBloodDonor';
import MarkAsSafe from './MarkAsSafe';
import EventAnnouncement from './EventAnnouncement';
import HelpJobRequest from './HelpJobRequest';
import { useAuthenticationState } from '../../context/Auth.context';
import CityChapter from './CityChapter';
import { ReactComponent as CityChapterIcon } from '../../asset/icons/CityChapterIcon.svg';
import UseToggleRefetch from '../../helpers/toggleRefetch';

function Dashboard() {
    const { user } = useAuthenticationState();
    const { response, loading, reFetch } = useAxiosGetMultiple({
        ecMembers: endpoints.statistics.entreprener,
        bloodDonor: endpoints.statistics.bloodDonor,
        cchAlumniCount: endpoints.statistics.cchAlumniCount,
        cchTotalHelp: endpoints.statistics.cchTotalHelp,

        inActiveAlumni:
            endpoints.statistics.inActiveAlumni + user?.CCH?.CityChapter_ID,
        unSafe: endpoints.statistics.cchUnsafe + user?.CCH?.CityChapter_ID,
        unRespondedSafeEvent:
            endpoints.statistics.cchUnrespondSafeEvent +
            user?.CCH?.CityChapter_ID,
        cchTotalAlumni:
            endpoints.statistics.cchTotalAlumni + user?.CCH?.CityChapter_ID,
    });

    UseToggleRefetch(reFetch);
    
    const {
        basics,
        ecMembers,
        inActiveAlumni,
        bloodDonor,
        cchAlumniCount,
        cchTotalHelp,
        unSafe,
        unRespondedSafeEvent,
        cchTotalAlumni,
        cchOverview,
        // cityChapterInclusion,
    } = response;

    // const cityChapterInclusion = response.cityChapterInclusion.filter(
    //     (data) => !!data.COUNT_ACTIVE_ALUMNI
    // );

    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                City Chapter Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip bg='#FF9A9A' title='Unsafe Response 🚨'>
                                {unSafe.unsafeResponse ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='Total Help Requests 🚨'>
                                {cchTotalHelp.TotalHelpRequests ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#C6FAD8' title='Total Job Requests'>
                                {basics?.totalHelpRequest ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#FAEDC6'
                                title='Total Alumni in City Chapter'
                            >
                                {cchTotalAlumni.totalAlumniCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#FAEDC6'
                                title='Inactive Alumni in City Chapter'
                            >
                                {inActiveAlumni.inActiveAlumniCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Mark as Safe Events'>
                                {unRespondedSafeEvent.notRespondedResponse ?? 0}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col md='4'>
                    <Entrepreneurs
                        entrepreners={ecMembers}
                        bloodDonor={bloodDonor.responseData}
                    />
                </Col>
                <Col md='14' className='gy-5'>
                    <MarkAsSafe />
                </Col>

                <Col md='14' className='gy-5'>
                    <Col className='d-flex'>
                        <CityChapterIcon />
                        <Col className='px-2'>
                            <div className='small h6 text-dark'>
                                Alumni Involvement in the City Chapter
                            </div>

                            <div
                                className='text-muted'
                                style={{ fontSize: '.75em' }}
                            >
                                Based on City Chapter
                            </div>
                        </Col>
                    </Col>
                    <Col md='14' className='gy-5 d-flex justify-content-center'>
                        <CityChapter cchAlumniCount={cchAlumniCount} />
                    </Col>
                </Col>

                <Col md='14' className='gy-5'>
                    <EventAnnouncement />
                </Col>
                <Col md='14' className='gy-5'>
                    <HelpJobRequest />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
