import React, { useState } from 'react';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useLocation } from 'react-router-dom';
import withAlert from '../../components/withAlert';
import { useAuthenticationState } from '../../context/Auth.context';
import DownloadExcelWithWantedColumns from '../../components/DownloadExcelWithWantedColumns';
import Loader from '../../components/Loader';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import GlobalSearch from '../../components/GlobalSearch';

function BatchWiseAlumniMap({ showAlert }) {
    const { state, stateBC } = useLocation();

    const { BATCH_CODE: batchId, BATCH_NAME: batchName, COURSE_NAME: courseName, insideID:insideID, COURSE_ID:courseID } = state;
    
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(stateBC || "");
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    

    let url = insideID==1?
                `${endpoints.batch.list}/${courseID}/${insideID}?page=${page}&limit=10`:
                `${endpoints.batch.list}/${batchId}/${insideID}?page=${page}&limit=10`;

    let downloadUrl = insideID==1?
                `${endpoints.batch.downloadExcelBC}/${courseID}/${insideID}`:
                `${endpoints.batch.downloadExcelBC}/${batchId}/${insideID}`;
                
    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`; 
    }
    
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const { user } = useAuthenticationState();
    const yesOrNo = {
        0: 'No',
        1: 'Yes',
    };

    const options = { month: 'long', day: 'numeric' };
    const cellModifier = {
        DateOfBirth: ({ value }) => {
            if (!value) return '';
            const [month, day] = value.split('-').map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString('en-US', options);
        },
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: 'Active',
                2: 'Inactive',
                3: 'Deactivated',
            };
            return status[value];
        },
        isBloodDonor: ({ value }) => {
            const status = {
                0: 'NO',
                1: 'YES',
            };
            return status[value];
        },
        user_type: ({ value }) => {
            const status = {
                1: 'Alumni',
                2: 'Student',
                3: 'Faculty',
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Full Access';
                case 2:
                    return 'No Access';
                case 3:
                    return 'Limited Access';

                default:
                    return value;
            }
        },
        bloodGroup: ({ value }) => {
            const BLOOD_GROUPS = {
                1: 'A +ve',
                2: 'A -ve',
                3: 'B +ve',
                4: 'B -ve',
                5: 'AB +ve',
                6: 'AB -ve',
                7: 'O +ve',
                8: 'O -ve'
            };
            return BLOOD_GROUPS[value] || 'Not Disclosed';
        },
    };

    if (fetchUtils.loading) {
        return <Loader />;
    }
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.batch}
                {...viewProps.BatchWiseAlumniMap}
                relevants={[
                    ...(viewProps.BatchWiseAlumniMap.relevants || []),
                ]}
                columnHeads={[
                    ...(viewProps.BatchWiseAlumniMap.columnHeads || []),
                ]}
                {...fetchUtils}
                immutable
                response={fetchUtils.response.responseData}
                name={insideID==1?`Alumni Details by ${courseName}`:`Alumni Details by ${batchName}`}
                cellModifier={cellModifier}
                irremovable
                insertable={false}
                downloadable={false}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm || stateBC}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
                headerExtras={
                    <>
                        <DownloadDataAsExcel
                            filename={insideID==1?`${courseName} - Course Wise List`:`${batchName} Batch Wise List`}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
            
            />
        </>
    );
}

export default withAlert(BatchWiseAlumniMap);
