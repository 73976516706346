import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ReportedPostsInReview from './ReportsInReview';
import BlockedPosts from './BlockedPosts';

const Reports = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div className='custom-tab'>
            <Tabs
                fill className='mb-4'
                mountOnEnter
                activeKey={activeTab}
                onSelect={handleTabChange}
                id="posts-reports-tabs"
                style={{ width: '50%' }}
            >
                <Tab eventKey="tab1" title="Reports In Review" >
                    <ReportedPostsInReview reFetchTrigger={activeTab === 'tab1'} />
                </Tab>
                <Tab eventKey="tab2" title="Blocked Posts" >
                    <BlockedPosts reFetchTrigger={activeTab === 'tab2'} />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Reports;
