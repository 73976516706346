import React, {useState} from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import ClassRepresentativeForm from './ClassRepresentativeForm';
import objCloneKeyValue from '../../helpers/objCloneKeyValue';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useLocation } from 'react-router-dom';

function ClassRepresentative() {
    // const fetchUtils = useAxiosGet(
    //     endpoints.classRep.get + '?page=1&limit=1000'
    // );

    // let classRepData = objCloneKeyValue(
    //     fetchUtils.response,
    //     'batch_code',
    //     'batchCode'
    // );

    // classRepData = objCloneKeyValue(
    //     classRepData,
    //     'class_rep_userid',
    //     'classRepUserId'
    // );
    // const url = !searchTerm
    //     ? `${endpoints.classRep.getList}?page=${page}&limit=10`
    //     : `${endpoints.classRep.getList}?searchTerm=${searchTerm}&page=${page}&limit=10`;
    
    const { state } = useLocation();
    const [searchTerm, setSearchTerm] = useState(state || "");


    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    let url = `${endpoints.classRep.getList}?page=${page}&limit=10`;
    let downloadUrl = endpoints.classRep.downloadExcel;
    
    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`; 
    }
    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    return (
        <>
        <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.classRep}
                Form={ClassRepresentativeForm}
                {...viewProps.ClassRep}
                relevants={[...(viewProps.ClassRep.relevants || [])]}
                columnHeads={[
                    ...(viewProps.ClassRep.columnHeads || []),

                ]}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                name={`Class Representatives`}
                addBtnLabel={`Add Class Representative`}
                showSearch={false}
                headerExtras={
                    <>

                        <DownloadDataAsExcel
                            filename={"Class Representative Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
                downloadable={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm || state}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
            />
            {/* <CRUDTable
                endpoints={endpoints.classRep}
                Form={ClassRepresentativeForm}
                {...viewProps.ClassRep}
                {...fetchUtils}
                response={classRepData}
            /> */}
        </>
    );
}

export default ClassRepresentative;
