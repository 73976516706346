import React, { useState } from 'react';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BatchForm from './BatchForm';
import { Link, useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
// import DownloadExcelWithWantedHeads from '../../components/DownloadExcelWithWantedColumns';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';


function Batches() {
  
     const { state } = useLocation();
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(state || "");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    // const url = `${endpoints.batch.get}/?page=${page}&limit=10`;
    // const fetchUtils = useAxiosGet(endpoints.batch.get);
    
    let url = `${endpoints.batch.get}/?page=${page}&limit=10`;
    let downloadUrl = endpoints.batch.downloadExcel;

    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`; 
    }
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const cellModifier = {
        BatchWiseAluminiList: ({ row }) => {
            const batchCode = row.original.BATCH_CODE; 

                return (
                    <Link to={'/alumini-map-list'} state={{...row.original,insideID:2}}>
                        Batch Wise Alumni List
                    </Link>
                );
            
        },
        BATCH_START_DATE: ({ value }) => new Date(value).toDateString(),
        BATCH_END_DATE: ({ value }) => new Date(value).toDateString(),
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                setTriggerApiCall={setTriggerApiCall}
                endpoints={endpoints.batch}
                Form={BatchForm}
                downloadExcelName='Batch'
                {...viewProps.Batch}
                cellModifier={cellModifier}
                // irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                // immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                // insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                {...fetchUtils}
                response={
                    fetchUtils.response.responseData
                }
                downloadable={false}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm || state}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
                headerExtras={
                    <>
                        <DownloadDataAsExcel
                            filename={"Batch Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
            />
        </>
    );
}

export default Batches;
