import React, { useState } from 'react';
import { CloseButton, FormControl } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

const GlobalSearch = ({ searchTerm, setPage, setSearchTerm, debounceDelay = 400, placeholder = 'Search...', ...props }) => {
    const [inputValue, setInputValue] = useState(searchTerm);

    const onChange = useAsyncDebounce((value) => {
        setSearchTerm(value || undefined);
        setPage(1);
    }, debounceDelay);

    return (
        <div className='position-relative'>
            <FormControl
                value={inputValue || ''}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    onChange(e.target.value);
                }}
                size='sm'
                placeholder={placeholder}
                {...props}
            />
            {inputValue && (
                <CloseButton
                    className='position-absolute clearFocusDecorate'
                    style={{ top: '4px', right: '3px', transform: 'scale(.7)' }}
                    onClick={() => {
                        setInputValue('');
                        setSearchTerm(''); // Reset search term
                    }}
                />
            )}
        </div>
    );
};

export default GlobalSearch;
