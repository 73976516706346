import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const ProfileCard = ({ userName, dpUrl, email, renderPost }) => {
    const [showProfile, setShowProfile] = useState(false);

    // Inline styles for the card and elements
    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        width: '250px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        fontFamily: 'Arial, sans-serif',
    };

    const imageStyle = {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
        marginBottom: '16px',
        objectFit: 'cover',
        border: '2px solid #ddd',
    };

    const nameStyle = {
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '8px 0',
        color: '#333',
    };

    const emailStyle = {
        fontSize: '14px',
        color: '#555',
        marginBottom: '16px',
    };

    const toggleButtonStyle = {
        cursor: 'pointer',
        color: '#fff',
        margin: '10px 0',
        fontSize: '16px',
        fontWeight: '500',
    };

    return (
        <>
            {/* Toggle Button */}
            <Button
                onClick={() => setShowProfile(!showProfile)}
                style={toggleButtonStyle}
                className="btnAnime"
            >
                {showProfile ? 'HIDE POST DETAILS' : 'SHOW POST DETAILS'}
            </Button>

            {/* Profile Details with Collapse */}


            {showProfile && <div style={cardStyle}>
                <h2>POSTED BY</h2>
                <img src={dpUrl} alt={`${userName}'s avatar`} style={imageStyle} />
                <div style={nameStyle}>{userName}</div>
                <div style={emailStyle}>{email}</div>
                {/* Render custom content */}
                {renderPost && <div>{renderPost}</div>}
            </div>
            }
        </>
    );
};

export default ProfileCard;
