import React, {useState} from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import PostWithWarning from '../../components/PostWithWarning';
import ModalWrapper from '../../components/ModalWrapper';
import { useAuthenticationState } from '../../context/Auth.context';
import StatusIndicator from '../../components/StatusIndicator';
import withAlert from '../../components/withAlert';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import GlobalSearch from '../../components/GlobalSearch';
import PaginatedTable from '../../components/table/PaginatedTable';

function HelpRequests({ showAlert }) {
    const { user } = useAuthenticationState();

    let getApi = endpoints.helpRequests.get;
    let getDownloadApi = endpoints.helpRequests.downloadExcel;

    if (['DELEGATE', 'DELEGATE + CCH'].includes(user.Role)) {
        getApi = endpoints.helpRequests.delegate + user.DELEGATE_BATCH_CODE;
        getDownloadApi = endpoints.helpRequests.downloadExcelDelegate  + user.DELEGATE_BATCH_CODE;
    } else if (['STUDENTCOUNCIL', 'CLASSREPRESENTATIVE', 'CLASSREP + STUDENT COUNCIL'].includes(user.Role)) {
        getApi = endpoints.helpRequests.studentHelpRequest;
        getDownloadApi = endpoints.helpRequests.downloadExcelStudent;
    } else {
        getApi = endpoints.helpRequests.get;
        getDownloadApi = endpoints.helpRequests.downloadExcel;
    }

    // const fetchUtils = useAxiosGet(getApi);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    let url = `${getApi}?page=${page}&limit=10`;
    let downloadUrl = getDownloadApi; 

    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`;
    }
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const cellModifier = {
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Request ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Alumni_HelpRequest_RESPONSE_ID !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'success',
                                'The help request has been approved'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.helpRequests.respond}
                        configBody={{
                            Alumni_HelpRequest_RESPONSE_COMMENT: 'nill',
                            Alumni_HelpRequest_RESPONSE_ID: '1',
                            Alumni_HelpRequest_ID:
                                row.original.Alumni_HelpRequest_ID,
                            Alumni_HelpRequest_AlumniID:
                                row.original.Alumni_HelpRequest_AlumniID,
                            Alumni_HelpRequest_TITLE:
                                row.original.Alumni_HelpRequest_TITLE,
                            NOTIFICATION_CONTENT: 'Help request approved',
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Request ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Alumni_HelpRequest_RESPONSE_ID !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'success',
                                'The help request has been rejected'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.helpRequests.respond}
                        configBody={{
                            Alumni_HelpRequest_RESPONSE_COMMENT: 'nill',
                            Alumni_HelpRequest_RESPONSE_ID: '2',
                            Alumni_HelpRequest_ID:
                                row.original.Alumni_HelpRequest_ID,
                            Alumni_HelpRequest_AlumniID:
                                row.original.Alumni_HelpRequest_AlumniID,
                            Alumni_HelpRequest_TITLE:
                                row.original.Alumni_HelpRequest_TITLE,
                            NOTIFICATION_CONTENT: 'Help request rejected',
                        }}
                    // successVarient='success'
                    // dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        Alumni_HelpRequest_TYPE: ({ value }) => {
            const types = {
                1: 'MEDICAL RELATED',
                2: 'CALAMITY RELATED',
                3: 'QUERIES',
                4: 'PERSONAL',
                5: 'LEGAL ADVICE',
                6: 'EMPLOYMENT RELATED',
            };
            return types[value];
        },
        Alumni_HelpRequest_PRIORITY: ({ value }) => {
            const types = {
                1: 'URGENT',
                2: 'IMMEDIATE',
                3: 'NOT URGENT',
            };
            return types[value];
        },
        Alumni_HelpRequest_STATUS: ({ value }) => {
            const types = {
                0: 'CANCELLED',
                1: 'ACTIVE',
                2: 'EXPIRED',
                3: 'RESPONDED',
                4: 'CLOSED',
            };
            return types[value];
        },
        Alumni_HelpRequest_BROADCAST_TYPE: ({ value, row }) => {
            const types = {
                1: 'ALL',
                2: row.original.BATCH_NAME,
            };
            return types[value];
        },
        Alumni_HelpRequest_RESPONSE_ID: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Alumni_HelpRequest_ExpiryDate: ({ value }) =>
            new Date(value).toLocaleDateString(),
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.helpRequests}
                irremovable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                ].includes(user.Role)}
                immutable
                insertable={false}
                name={viewProps.HelpRequests.name}
                relevants={[
                    ...(viewProps.HelpRequests.relevants || []),
                ]}
                // columnHeads={[
                //     ...(viewProps.Promos.columnHeads||[])
                // ]}
                columnHeads={[
                    ...(['STUDENTCOUNCIL', 'CLASSREPRESENTATIVE', 'CLASSREP + STUDENT COUNCIL'].includes(user.Role)
                        ? ['Student ID', 'Student Name']
                        : ['User ID', 'User Name']),
                    // ...viewProps.HelpRequests.columnHeads.slice(2),
                    ...(viewProps.HelpRequests.columnHeads||[])
                ]}
                cellModifier={cellModifier}
                // formSize='md'
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search ..."
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Help Requests"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />

            {/* <CRUDTable
                {...viewProps.HelpRequests}
                
                
                {...fetchUtils}
                endpoints={endpoints.helpRequests}
            /> */}
        </>
    );
}

export default withAlert(HelpRequests);
