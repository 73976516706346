// import React from 'react';
// import CRUDTable from '../../components/table/CRUDTable';
// import { useAxiosGet } from '../../hooks/axiosHooks';
// import endpoints from '../../endpoints';
// import viewProps from '../viewProps';
// import { Link } from 'react-router-dom';
// import MarkAsSafeForm from './MarkAsSafeForm';

// function MarkAsSafe() {
//     const fetchUtils = useAxiosGet(endpoints.scmarkAsSafe.get);
//     const cellModifier = {
//         Response: ({ row }) => (
//             <Link
//                 to={'/safety-response'}
//                 state={row.original.Alumni_MarkAsSafe_EventID}
//             >
//                 User Responses
//             </Link>
//         ),
//         Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
//             new Date(value).toDateString(),
//         Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
//             if (value) {
//                 return new Date(value).toDateString();
//             } else {
//                 return value;
//             }
//         },
//     };
//     return (
//         <>
//             <CRUDTable
//                 endpoints={endpoints.scmarkAsSafe}
//                 Form={MarkAsSafeForm}
//                 {...viewProps.MarkAsSafe}
//                 cellModifier={cellModifier}
//                 addBtnLabel='Add Event'
//                 formSize='md'
//                 {...fetchUtils}
//                 immutable
//             />
//         </>
//     );
// }

// export default MarkAsSafe;
import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import MarkAsSafeForm from './MarkAsSafeForm';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function MarkAsSafe() {
    let getApi;
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    let url = `${endpoints.scmarkAsSafe.get}?page=${page}&limit=10`;
    let downloadUrl = `${endpoints.scmarkAsSafe.downloadExcel}`

    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }
     const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });
     const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    const cellModifier = {
        Response: ({ row }) => (
            <Link
                to={'/safety-response'}
                state={row.original.Alumni_MarkAsSafe_EventID}
            >
                User Responses
            </Link>
        ),
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.markAsSafe}
                Form={MarkAsSafeForm}
                {...viewProps.MarkAsSafe}
                cellModifier={cellModifier}
                addBtnLabel='Add Event'
                formSize='md'
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Mark As Safe Event response Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default MarkAsSafe;
