import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import GoverninCouncilForm from './GoverninCouncilForm';
import { useAuthenticationState } from '../../context/Auth.context';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function GoverningCouncil() {
    const { user } = useAuthenticationState();

    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    let url = `${endpoints.governingCouncil.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.governingCouncil.downloadExcel

    if (searchTerm) {
        url = `${endpoints.governingCouncil.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });


    const cellModifier = {
        EC_Member_Elected_Date: ({ value }) => new Date(value).toDateString(),
    };

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.governingCouncil}
                {...viewProps.GoverningCouncil}
                cellModifier={cellModifier}
                Form={GoverninCouncilForm}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                addBtnLabel='Add Member'
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                // immutable={!['DELEGATE + CCH', 'DELEGATE'].includes(user.Role)}
                // insertable={!['DELEGATE + CCH', 'DELEGATE'].includes(user.Role)}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setPage={setPage}
                        setSearchTerm={handleSearch}
                        placeholder="Search Alumni by name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Governing Council Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default GoverningCouncil;
