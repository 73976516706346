import React, { useState } from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useLocation } from 'react-router-dom';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';


function SafetyResponse() {
    const { state } = useLocation();
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    let url = `${endpoints.safetyResponse.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.safetyResponse.downloadExcel;

    if (state) {
        if (searchTerm) {
            url += `&eventId=${state}&searchTerm=${searchTerm}`;
            downloadUrl += `?eventId=${state}&searchTerm=${searchTerm}`;
        } else {
            url += `&eventId=${state}`;
            downloadUrl += `?eventId=${state}`;
        }
    } else if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`;
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };
    // let response = [];
    // if (state) {
    //     response = fetchUtils.response.filter(
    //         (res) => Number(res.Alumni_MarkAsSafe_EventID) === Number(state)
    //     );
    // } else {
    //     response = fetchUtils.response;
    // }
    const options = { month: 'long', day: 'numeric' };

    const cellModifier = {
        DateOfBirth: ({ value }) => {
            if (!value) return '';
            const [month, day] = value.split('-').map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString('en-US', options);
        },
        // DateOfBirth: ({ value }) => new Date(value).toDateString(),
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                {...viewProps.SafetyResponse}
                cellModifier={cellModifier}
                insertable={false}
                immutable
                irremovable
                {...fetchUtils}
                response={fetchUtils.response?.responseData}
                showSearch={false}
                customSearch={<GlobalSearch
                    searchTerm={searchTerm}
                    setPage={setPage}
                    setSearchTerm={handleSearch}
                    placeholder="Search by name"
                />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Safety response Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default SafetyResponse;
